@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


* {
    list-style: none;
}
body {
    background: #f5f6fa;
}

.projContainer {
    display: flex;
    justify-content: center;
  }

.projContainer .projBox{
    background: transparent;
    position: fixed;
    top: 15%;
    left: 20%;
    width: 1125px;
    height: 625px;
    padding: 20px 0;
    transition: all 0.5s ease;
    /* border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white; */
    border-radius: 10px;
}

.serviceContainer{
    row-gap: 2rem;
    text-align: center;
}

.serviceImage {
    display: flex;
    justify-self: center;
    /* align-items: center; */
}

.project_box {
    min-width: 170px;
    min-height: 210px;
    width: 300px;
    height: 275 px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px 15px;
    border-radius: 10px;
    margin: 2rem 0;
    margin-left: 5rem;
    margin-right: 5rem;
}

.project_box img {
    height: 350px;
    width: 350px;
    object-fit: cover;
    border-radius: 30px;
}

.project_box h2 {
    color: teal;
    font-size: 2rem;
}


.project_box {
    position: relative;
  }
  
  .project_box img {
    height: 350px;
    width: 350px;
    object-fit: cover;
    border-radius: 30px;
  }
  
  .project_box .overlay {
    position: absolute;
    top: 9px;
    bottom: 0;
    left: -68px;
    right: 0;
    opacity: 0;
    height: 85%;
    width: 164%;
    border-radius: 30px;
    transition: .5s ease;
    background-color: rgba(0, 180, 186, 0.8);
  }
  
  .project_box:hover .overlay {
    opacity: 1;
  }
  
  .project_box .text {
    color: white;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
  
  .button {
    display: inline-block;
    padding: 8px 16px;
    border: 2px solid rgba(0, 180, 186, 0.8);
    border-radius: 4px;
    background-color: rgba(0, 180, 186, 0.8);
    color: white;
    font-size: 16px;
    text-decoration: none;
    position: absolute;
    bottom: -165px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .button:hover {
    background-color: white;
    color: #008CBA;
  }
  
  .prohead{
    color: white;

    /* typing animation */
   overflow: hidden;
   border-right: .60em solid white; 
   white-space: nowrap; 
   margin: 0 auto; 
   animation: 
   typing 1.5s steps(30, end),
   blink-caret .5s step-end infinite,
   remove-border 0.7s step-end forwards;

  }

  .desText {
    /* color: white; */
  }

  .pTag{
    color: white;
    /* typing animation */
    opacity: 0;
   overflow: hidden;
   border-right: .60em solid white; 
   white-space: nowrap; 
   margin: 0 auto; 
   animation: 
   appear 0.1s steps(30, end) 2s forwards, 
   typing 1.5s steps(30, end) 2s,
   blink-caret .5s step-end infinite 2s,
   remove-border 2.7s step-end forwards 2s;
  }
  
  .github-link {
    color: white;
  }

  .github-link:hover{
    color: gray;
    transform: scale(1.3);
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    .projContainer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    
    .projContainer .projBox {
      background: transparent;
      position: fixed;
      top: 13.75%;
      left: 39%;
      width: 294.25px;
      height: 713.25px;
      padding: 5px 0;
      transition: all 0.5s ease;
      /* border: 0.25px solid white; */
      border-radius: 2.5px;
    }
    
    .serviceContainer {
      row-gap: 0.5rem;
      text-align: center;
      flex-wrap: wrap;
      
    }
    
    .serviceImage {
      display: flex;
      justify-self: center;
      flex-direction: column;
    }
    
    .project_box {
      min-width: 42.5px;
      min-height: 52.5px;
      width: 75px;
      height: 78.75px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0.75px 1.25px 5px rgba(0, 0, 0, 0.15);
      padding: 2.5px 3.75px;
      border-radius: 2.5px;
      margin: 3.3rem 0;
      margin-left: 6.2rem;
      margin-right: 1.25rem;
    }
    
    .project_box img {
      height: 167.5px;
      width: 197.5px;
      object-fit: cover;
      border-radius: 7.5px;
    }
    
    .project_box h2 {
      color: teal;
      font-size: 0.5rem;
    }
    
    .project_box {
      position: relative;
    }
    
    .project_box .overlay {
      position: absolute;
      top: 2.25px;
      bottom: 0;
      left: -62px;
      right: 0;
      opacity: 0;
      height: 168px;
      width: 198px;
      border-radius: 7.5px;
      transition: 0.5s ease;
      background-color: rgba(0, 180, 186, 0.8);
    }
    
    .project_box:hover .overlay {
      opacity: 1;
    }
    
    .project_box .text {
      color: white;
      font-size: 0.67rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
    }
    
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }
    
    .button {
      display: inline-block;
      padding: 2px 4px;
      border: 0.5px solid rgba(0, 180, 186, 0.8);
      border-radius: 1px;
      background-color: rgba(0, 180, 186, 0.8);
      bottom: -60px

      /* display: inline-block;
        padding: 8px 16px;
        border: 2px solid rgba(0, 180, 186, 0.8);
        border-radius: 4px;
        background-color: rgba(0, 180, 186, 0.8);
        color: white;
        font-size: 16px;
        text-decoration: none;
        position: absolute;
        bottom: -165px;
        left: 50%;
        transform: translateX(-50%); */
    }

    .desText .pTag {
      margin-top: 15%;
      font-size: 5.2px;
      /* font-size: 7.1px; */
      word-wrap: break-word;
      overflow-wrap: break-word; 
    }

  }

  

  @keyframes typing {
    from { width: 0 }
    to { width: 40% }
  }
  
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: white }
  }
  
  @keyframes remove-border {
    from {
      border-right-width: 0.6em;
    }
    to {
      border-right-width: 0em;
    }
  }

  @keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }