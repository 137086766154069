@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');


* {
    list-style: none;
}
body {
    background: #f5f6fa;
}

.servContainer {
    display: flex;
    justify-content: center;
  }

.servContainer .servBox{
    background: transparent;
    position: fixed;
    top: 15%;
    left: 20%;
    width: 1125px;
    height: 625px;
    padding: 20px 0;
    transition: all 0.5s ease;
    /* border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white; */
    border-radius: 10px;
}

.hservice{
    color: white;

  transform: translateX(-7%);

   /* typing animation */
   overflow: hidden;
   border-right: .60em solid white; 
   white-space: nowrap; 
   margin: 0 auto; 
   animation: 
   typing 1.5s steps(30, end),
   blink-caret .5s step-end infinite,
   remove-border 1s step-end forwards;
}

/* -----styling for dice----- */
.scene {
  width: 400px;
  height: 400px;
  margin: 40px;
  margin-left: 280px;
  margin-top: 100px;
  perspective:600px;
  /* border: 1px solid red; */
}

.rollBtn{
  background-color: teal;
  color:white;
  border:none;
  border-radius: 10px;
  margin:auto 0;
  font-size:1.5em;
  font-weight: 600;
  margin-top:1em;
  width:200px;
}

.rollBtn:hover {
  background-color: #0ff;
  box-shadow:
  0px 0px 5px #fff,
  0px 0px 10px #f0f,
  0px 0px 15px #0ff
}

/* -----styling for 3D Cube---- */
.cube {
  width:400px;
  height: 400px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-200px);
  transition: transform 1s;
}

/* ---styling for 3D Cube transforms / animations on face select (on roll)--- */
.cube.show-1  { transform: translateZ(-200px) rotateY(   0deg); }
.cube.show-2  { transform: translateZ(-200px) rotateY(-180deg); }
.cube.show-3  { transform: translateZ(-200px) rotateY( -90deg); }
.cube.show-4   { transform: translateZ(-200px) rotateY(  90deg); }
.cube.show-5   { transform: translateZ(-200px) rotateX( -90deg); }
.cube.show-6 { transform: translateZ(-200px) rotateX(  90deg); }

/* ---universal style for cube faces --- */
.cube__face {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 4px solid white;
  line-height: 200px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---individual styling for each numbers face---  */
.cube__face--1  { background: black;  opacity: 0.65;}
.cube__face--3  { background: black;   opacity: 0.65; }
.cube__face--2  {  background: black;   opacity: 0.65; }
.cube__face--4  {  background: black;   opacity: 0.65; }
.cube__face--5   {  background: black;   opacity: 0.65; }
.cube__face--6 {  background: black;  opacity: 0.65; }

.cube__face--1  { transform: rotateY(  0deg) translateZ(200px); }
.cube__face--2   { transform: rotateY(180deg) translateZ(200px); }
.cube__face--3  { transform: rotateY( 90deg) translateZ(200px); }
.cube__face--4   { transform: rotateY(-90deg) translateZ(200px); }
.cube__face--5   { transform: rotateX( 90deg) translateZ(200px); }
.cube__face--6 { transform: rotateX(-90deg) translateZ(200px); }

label { margin-right: 10px; }

@media (max-width: 1024px) {
  .servContainer .servBox {
    left: 10%;
    width: 800px;
    height: 500px;
  }

  .hservice {
    font-size: 24px;
  }

  
}

@media (max-width: 768px) {
  .servContainer .servBox {
    left: 45%;
    top: 25%;
    width: 270px;
    height: 500px;
    /* border: 1px solid orange; */
  }

  .hservice {
    font-size: 30px;
    transform: translateX(-5%);
  }

  .scene {
    width: 240px;
    height: 240px;
    margin: 4px;
    
    margin-top: 50px;
    perspective:200px;
    /* border: 1px solid red; */
  }
  
  .rollBtn{
    background-color: teal;
    color:white;
    border:none;
    border-radius: 10px;
    margin:auto 0;
    font-size:1.5em;
    font-weight: 600;
    margin-top:2.4em;
    width:200px;
  }
  
  .rollBtn:hover {
    background-color: #0ff;
    box-shadow:
    0px 0px 5px #fff,
    0px 0px 10px #f0f,
    0px 0px 15px #0ff
  }
  
  /* -----styling for 3D Cube---- */
  .cube {
    width:240px;
    height: 240px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-120px);
    transition: transform 1s;
  }
  
  /* ---styling for 3D Cube transforms / animations on face select (on roll)--- */
  .cube.show-1  { transform: translateZ(-120px) rotateY(   0deg); }
  .cube.show-2  { transform: translateZ(-120px) rotateY(-180deg); }
  .cube.show-3  { transform: translateZ(-120px) rotateY( -90deg); }
  .cube.show-4   { transform: translateZ(-120px) rotateY(  90deg); }
  .cube.show-5   { transform: translateZ(-120px) rotateX( -90deg); }
  .cube.show-6 { transform: translateZ(-120px) rotateX(  90deg); }
  
  /* ---universal style for cube faces --- */
  .cube__face {
    position: absolute;
    width: 240px;
    height: 240px;
    border: 4px solid white;
    line-height: 200px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* ---individual styling for each numbers face---  */
  .cube__face--1  { background: black;  opacity: 0.65;}
  .cube__face--3  { background: black;   opacity: 0.65; }
  .cube__face--2  {  background: black;   opacity: 0.65; }
  .cube__face--4  {  background: black;   opacity: 0.65; }
  .cube__face--5   {  background: black;   opacity: 0.65; }
  .cube__face--6 {  background: black;  opacity: 0.65; }
  
  .cube__face--1  { transform: rotateY(  0deg) translateZ(120px); }
  .cube__face--2   { transform: rotateY(180deg) translateZ(120px); }
  .cube__face--3  { transform: rotateY( 90deg) translateZ(120px); }
  .cube__face--4   { transform: rotateY(-90deg) translateZ(120px); }
  .cube__face--5   { transform: rotateX( 90deg) translateZ(120px); }
  .cube__face--6 { transform: rotateX(-90deg) translateZ(120px); }
  
  label { margin-right: 10px; }

  
}

@keyframes typing {
    from { width: 0 }
    to { width: 40% }
  }
  
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: white }
  }
  
  @keyframes remove-border {
    from {
      border-right-width: 0.6em;
    }
    to {
      border-right-width: 0em;
    }
  }

  @keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }