.App {
    text-align: center;
  }
  
  .App-header {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative; /* Add this line */
    width: 60vw; /* changed from 640px to 100vw */
    height: 90vh; /* changed from 480px to 100vh */
    margin: auto; /* Add this line */
  }
  
  
  .terminator-vision-title {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
  }
  
  
  .App-webcam,
  .App-canvas {
    position: absolute;
    width: 440px;
    height: 280px;
  }
  
  .App-webcam video {
    width: 100%;
    height: 100%;
  }
  
  .App-canvas {
    width: 100%;
    height: 100%;
  }
  
  .left-text {
    position: absolute;
    left: 5%; /* added to align to the left */
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-family: 'Courier New', Courier, monospace;
    /* font-family: 'Open Sans', sans-serif; */
    /* font-weight: bold; */
    font-size: 26px;
    line-height: 1.4;
    width: 50%; /* added to take up half of the screen */
    text-align: left; /* added to align text to the left */
  }
  
  .right-text {
    position: absolute;
    right: 5%; /* added to align to the right */
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-size: 26px;
    line-height: 1.4;
    width: 50%; /* added to take up half of the screen */
    text-align: right; /* added to align text to the right */
  }
  
  
  .modelinfo {
    text-align: right;
  }
  
  @keyframes flash {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .my-svg {
    width: 100%;
    height: auto;
    max-width: 150px; /* or adjust to the desired size */
    max-height: 150px; /* or adjust to the desired size */
    animation: flash 1s infinite;
  }
  
  .flash {
    animation: flash-animation 0.5s infinite;
  }
  
  @keyframes flash-animation {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
  
  .click-me-button{
    position: absolute;
    /* font-family: 'Courier New', Courier, monospace; */
    font-family: 'Open Sans', sans-serif;
    /* font-weight: bold; */
    font-size: 25px;
    bottom: 40px; /* adjust this value to position the button vertically */
    left: 50%;
    color: white;
    width: 30%;
    background-color: transparent;
    border: 2px solid white;
    padding: 10px 20px;
    transform: translateX(-50%);
    font-weight: bold;
    border-radius: 10px;
    
    /* typing animation */
   overflow: hidden;
   border-right: .60em solid white; 
   white-space: nowrap; 
   margin: 0 auto; 
   animation: 
   typing 1.5s steps(30, end),
   blink-caretVR .5s step-end infinite,
   remove-border 1.7s step-end forwards;
  }

  @media (max-width: 768px) {
    .left-text {
      position: absolute;
      left: -28%; /* added to align to the left */
      top: 50%;
      transform: translateY(-50%);
      color: white;
      font-family: 'Courier New', Courier, monospace;
      /* font-family: 'Open Sans', sans-serif; */
      /* font-weight: bold; */
      font-size: 19px;
      line-height: 1.4;
      width: 50%; /* added to take up half of the screen */
      text-align: left; /* added to align text to the left */
    }

    .right-text {
      position: absolute;
      right: -25%; /* added to align to the right */
      top: 53.4%;
      transform: translateY(-50%);
      color: white;
      font-family: 'Courier New', Courier, monospace;
      font-size: 19px;
      line-height: 1.4;
      width: 50%; /* added to take up half of the screen */
      text-align: right; /* added to align text to the right */
    }
    
    .click-me-button{
      position: absolute;
      /* font-family: 'Courier New', Courier, monospace; */
      font-family: 'Open Sans', sans-serif;
      /* font-weight: bold; */
      font-size: 25px;
      bottom: 40px; /* adjust this value to position the button vertically */
      left: 47%;
      color: white;
      width: 69%;
      background-color: transparent;
      border: 2px solid white;
      padding: 10px 20px;
      transform: translateX(-50%);
      font-weight: bold;
      border-radius: 10px;
      
    }
  }
  
