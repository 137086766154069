/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');


* {
    list-style: none;
}
body {
    background: #f5f6fa;
}

.contContainer {
    display: flex;
    justify-content: center;
  }

.contContainer .contBox{
    background: transparent;
    position: fixed;
    top: 15%;
    left: 20%;
    width: 1125px;
    height: 625px;
    padding: 20px 0;
    transition: all 0.5s ease;
    /* border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white; */
    border-radius: 10px;
}


.hcont{
    color: white;
    font-family: 'Open Sans', sans-serif;
    /* typing animation */
   overflow: hidden;
   border-right: .60em solid white; 
   white-space: nowrap; 
   margin: 0 auto; 
   animation: 
   typing 1.5s steps(30, end),
   blink-caret .5s step-end infinite,
   remove-border 0.7s step-end forwards;
}

label {
    color: white;
  }
  
  .contact_input {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: black;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    margin-bottom: 2rem;
    border: 2.6px solid teal;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .contact_input_E {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: black;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    margin-bottom: 2rem;
    border: 2.6px solid teal;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  
  .contact_input_two {
    width: 100%;
    padding: 0.95rem 1rem;
    font-size: 1rem;
    color: black;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    margin-bottom: 2rem;
    border: 2.6px solid teal;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

   .contact_input_two:not(:focus) {
    background-color: #0ff; 
  }
  
  .contact_input::placeholder {
    color: gray;
  }

  .CSbutton, .contact_button {
    margin-top: 25px;
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: white;
    background-color: teal;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
  }
  
  .CSbutton:hover, .contact_button:hover {
    background-color: #0ff;
    box-shadow:
    0px 0px 5px #fff,
    0px 0px 10px #f0f,
    0px 0px 15px #0ff
  }
  
  .contactContainerSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding-top: 4rem;
}

@media (max-width: 768px) {
  .contContainer {
    display: flex;
    justify-content: center;
  }

  .contContainer .contBox {
    background: transparent;
    position: fixed;
    top: 15%;
    left: 35%; /* Adjust the left position as needed for phone screens */
    right: 2%; /* Adjust the right position as needed for phone screens */
    width: auto; /* Remove fixed width to allow content to expand */
    height: 679px;
    padding: 20px 0;
    transition: all 0.5s ease;
    border-radius: 10px;
  }

  .hcont {
    color: white;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
    border-right: .60em solid white;
    white-space: nowrap;
    margin: 0 auto;
    animation: typing 1.5s steps(30, end), blink-caret .5s step-end infinite,
      remove-border 0.7s step-end forwards;
  }

  label {
    color: white;
  }

  .contact_input,
  .contact_input_E,
  .contact_input_two {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: black;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    margin-bottom: 2rem;
    border: 2.6px solid teal;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .contact_input_two {
    padding: 0.95rem 1rem;
  }

  .contact_input_two:not(:focus) {
    background-color: #0ff;
  }

  .contact_input::placeholder {
    color: gray;
  }

  .CSbutton,
  .contact_button {
    margin-top: 25px;
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: white;
    background-color: teal;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
  }

  .CSbutton:hover,
  .contact_button:hover {
    background-color: #0ff;
    box-shadow: 0px 0px 5px #fff, 0px 0px 10px #f0f, 0px 0px 15px #0ff;
  }

  .contactContainerSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding-top: 4rem;
  }

}

@keyframes typing {
    from { width: 0 }
    to { width: 40% }
  }
  
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: white }
  }
  
  @keyframes remove-border {
    from {
      border-right-width: 0.6em;
    }
    to {
      border-right-width: 0em;
    }
  }

  @keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }